module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"um-what-day-is-it","short_name":"um-what-day-is-it","start_url":"/","background_color":"#663399","theme_color":"#663399","theme_color_in_head":false,"display":"standalone","icon":"static/favicon.png","legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"827b51c2cc8c12b20f706f63b3677f21"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
